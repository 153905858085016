
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            pattern: [
                'xoxoxoxoxoxoxoxoxoxoxoxoxoxox',
                'oxoxoxoxoxoxoxoxoxoxoxoxoxoxo',
                'xoxoxoxoxoxoxoxoxoxoxoxoxoxox',
                'oxoxoxoxoxoxoxoxoxoxoxoxoxoxo',
                'xoxoxoxoxoxoxoxoxoxoxoxoxoxox'
            ],
            colorMask: [
                'p               p   p        ',
                '       a                   a ',
                '    p                   p    ',
                ' a               a   a       ',
                '            a                '
            ],
            iconMask: [
                'g               g   g        ',
                '       g                   g ',
                '    c                   c    ',
                ' g               g   g       ',
                '            c                '
            ]
        }
    },

    methods: {
        getColor(char: string) {
            if (char === 'p') return 'primary'
            if (char === 'a') return 'blue-grey'
            return `grey ${Math.random() > 0.5 ? 'lighten-4' : 'lighten-3'}`
        },
        getIcon(char: string) {
            if (char === 'c') return 'mdi-cube-outline'
            if (char === 'g') return 'mdi-cog-outline'
        }
    },
})
